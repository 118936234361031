/* eslint-disable import/prefer-default-export */
import { PublicTvCampaignGetActiveCampaignResponse } from '@les-sherpas/sherpas-toolbox';
import axios from 'axios';
import server from 'config';

export const apiGetActiveCampaign =
  async (): Promise<PublicTvCampaignGetActiveCampaignResponse> => {
    try {
      const { data } =
        await axios.get<PublicTvCampaignGetActiveCampaignResponse>(
          `${server}tv-campaign/public/get-active-campaign`,
          { validateStatus: (status) => status === 200 }
        );

      return data;
    } catch {
      return null;
    }
  };
